// Ionic Variables and Theming. For more info, please see:
// http://ionicframework.com/docs/theming/

// @import "../assets/css/font-awesome.min.css";
@import "../assets/css/font-awesome-animation.css";
/** Ionic CSS Variables **/
:root {

  /** primary **/
  --ion-color-primary: #3880ff;
  --ion-color-primary-rgb: 56,128,255;
  --ion-color-primary-contrast: #ffffff;
  --ion-color-primary-contrast-rgb: 255,255,255;
  --ion-color-primary-shade: #3171e0;
  --ion-color-primary-tint: #4c8dff;

  /** secondary **/
  --ion-color-secondary: #0cd1e8;
  --ion-color-secondary-rgb: 12,209,232;
  --ion-color-secondary-contrast: #ffffff;
  --ion-color-secondary-contrast-rgb: 255,255,255;
  --ion-color-secondary-shade: #0bb8cc;
  --ion-color-secondary-tint: #24d6ea;

  /** tertiary **/
  --ion-color-tertiary: #7044ff;
  --ion-color-tertiary-rgb: 112,68,255;
  --ion-color-tertiary-contrast: #ffffff;
  --ion-color-tertiary-contrast-rgb: 255,255,255;
  --ion-color-tertiary-shade: #633ce0;
  --ion-color-tertiary-tint: #7e57ff;

  /** success **/
  --ion-color-success: #10dc60;
  --ion-color-success-rgb: 16,220,96;
  --ion-color-success-contrast: #ffffff;
  --ion-color-success-contrast-rgb: 255,255,255;
  --ion-color-success-shade: #0ec254;
  --ion-color-success-tint: #28e070;

  /** warning **/
  --ion-color-warning: #ffce00;
  --ion-color-warning-rgb: 255,206,0;
  --ion-color-warning-contrast: #ffffff;
  --ion-color-warning-contrast-rgb: 255,255,255;
  --ion-color-warning-shade: #e0b500;
  --ion-color-warning-tint: #ffd31a;

  /** danger **/
  --ion-color-danger: #f04141;
  --ion-color-danger-rgb: 245,61,61;
  --ion-color-danger-contrast: #ffffff;
  --ion-color-danger-contrast-rgb: 255,255,255;
  --ion-color-danger-shade: #d33939;
  --ion-color-danger-tint: #f25454;

  /** dark **/
  --ion-color-dark: #000000;
  --ion-color-dark-rgb: 34,34,34;
  --ion-color-dark-contrast: #ffffff;
  --ion-color-dark-contrast-rgb: 255,255,255;
  --ion-color-dark-shade: #1e2023;
  --ion-color-dark-tint: #383a3e;

  /** medium **/
  --ion-color-medium: #989aa2;
  --ion-color-medium-rgb: 152,154,162;
  --ion-color-medium-contrast: #ffffff;
  --ion-color-medium-contrast-rgb: 255,255,255;
  --ion-color-medium-shade: #86888f;
  --ion-color-medium-tint: #a2a4ab;

  /** light **/
  --ion-color-light: #ffffff;//#f4f5f8;
  --ion-color-light-rgb: 244,244,244;
  --ion-color-light-contrast: #000000;
  --ion-color-light-contrast-rgb: 0,0,0;
  --ion-color-light-shade: #d7d8da;
  --ion-color-light-tint: #f5f6f9;

  --ion-color-darkgray: #22222f;
  --ion-color-darkgray-rgb: 255, 215, 0;
  --ion-color-darkgray-contrast: #ffffff;
  --ion-color-darkgray-contrast-rgb: 240, 240, 240;
  --ion-color-darkgray-shade: #3d3d3d;
  --ion-color-darkgray-tint: #464646;
  
  /** smoke **/
  --ion-color-smoke: #ececec;//#f4f5f8;
  --ion-color-smoke-rgb: 244,244,244;
  --ion-color-smoke-contrast: #000000;
  --ion-color-smoke-contrast-rgb: 0,0,0;
  --ion-color-smoke-shade: #d7d8da;
  --ion-color-smoke-tint: #f5f6f9;

  --ion-color-transparent: rgba(0, 0, 0, 0.0);

  /** lcd **/
  --ion-color-lcd: #dcfdb5;
  --ion-color-lcd-rgb: 244,244,244;
  --ion-color-lcd-contrast: #000000;
  --ion-color-lcd-contrast-rgb: 0,0,0;
  --ion-color-lcd-shade: #9cad75;
  --ion-color-lcd-tint: #dcedb5;

  
  --ion-color-zaimucolor: #f07841;
  
  --ion-color-nijicolor: #ccccbb;

  --ion-color-easy: #30a128;
  --ion-color-normal: #2c8fa8;
  --ion-color-hard: #ffaa77;
  --ion-color-hell: #902121;
  
  --ion-color-toggleunlock: #ffff00;
  --ion-color-togglelock-handle: #a8a7a7;
  --ion-color-togglelock: #ffffff;
  //--ion-color-toggleunlock: #a1a1a1;

  --ion-font-family: 'My Custom Font';

}

@font-face {
  font-family: 'My Custom Font';
  font-weight: normal;
  src: url("../assets/fonts/logoTypeGothic.otf") format('opentype');
}

@font-face {
  font-family: 'mondaiBunFont';
  font-weight: normal;
  src: url("../assets/fonts/SourceHanSerifJP/SourceHanSerifJP-SemiBold.otf") format('opentype');
  -webkit-font-smoothing: antialiased;

  -moz-osx-font-smoothing: grayscale;
}

@font-face {
  font-family: 'mondaiBunFont';
  font-weight: bold;
  // src: url("../assets/fonts/logoTypeGothic.otf") format('opentype');
  // font-size: 0.5em;
  src: url("../assets/fonts/SourceHanSerifJP/SourceHanSerifJP-SemiBold.otf") format('opentype');
  // src: url("../assets/fonts/mogihaPen.ttf") format('opentype');
}


@font-face {
  font-family: '7bar';
  font-weight: normal;
  src: url("../assets/fonts/7barP.otf") format('opentype');
}

@font-face {
  font-family: 'Choplin';
  font-weight: normal;
  src: url("../assets/fonts/Choplin-Medium-DEMO.otf") format('opentype');
}

@font-face {
  font-family: 'trajan';
  font-weight: normal;
  src: url("../assets/fonts/trajanpro-bold.otf") format('opentype');
}


.ion-color-darkgray {
  --ion-color-base: var(--ion-color-darkgray) !important;
  --ion-color-base-rgb: var(--ion-color-darkgray-rgb) !important;
  --ion-color-contrast: var(--ion-color-darkgray-contrast) !important;
  --ion-color-contrast-rgb: var(--ion-color-darkgray-contrast-rgb) !important;
  --ion-color-shade: var(--ion-color-darkgray-shade) !important;
  --ion-color-tint: var(--ion-color-darkgray-tint) !important;
}

.ion-color-smoke {
  --ion-color-base: var(--ion-color-smoke) !important;
  --ion-color-base-rgb: var(--ion-color-smoke-rgb) !important;
  --ion-color-contrast: var(--ion-color-smoke-contrast) !important;
  --ion-color-contrast-rgb: var(--ion-color-smoke-contrast-rgb) !important;
  --ion-color-shade: var(--ion-color-smoke-shade) !important;
  --ion-color-tint: var(--ion-color-smoke-tint) !important;
}


.ion-color-lcd {
  --ion-color-base: var(--ion-color-lcd) !important;
  --ion-color-base-rgb: var(--ion-color-lcd-rgb) !important;
  --ion-color-contrast: var(--ion-color-lcd-contrast) !important;
  --ion-color-contrast-rgb: var(--ion-color-lcd-contrast-rgb) !important;
  --ion-color-shade: var(--ion-color-lcd-shade) !important;
  --ion-color-tint: var(--ion-color-lcd-tint) !important;
}

.ion-color-zaimucolor {
  --ion-color-base: var(--ion-color-zaimucolor) !important;
}

.ion-color-nijicolor {
  --ion-color-base: var(--ion-color-nijicolor) !important;
}

.ion-color-easy {
  --ion-color-base: var(--ion-color-easy) !important;
}

.ion-color-normal {
  --ion-color-base: var(--ion-color-normal) !important;
}

.ion-color-hard {
  --ion-color-base: var(--ion-color-hard) !important;
}

.ion-color-hell {
  --ion-color-base: var(--ion-color-hell) !important;
}

.ion-color-toggleunlock {
  --ion-color-base: var(--ion-color-toggleunlock) !important;
  --background: var(--ion-color-togglelock) !important;
  ////--background-checked: var(--ion-color-toggleunlock) !important;
  --handle-background: var(--ion-color-togglelock-handle) !important;//OFF時のハンドルの色
  ////--background-checked: var(--ion-color-toggleunlock) !important;
}
