/*
 * App Global CSS
 * ----------------------------------------------------------------------------
 * Put style rules here that you want to apply globally. These styles are for
 * the entire app and not just one component. Additionally, this file can be
 * used as an entry point to import other CSS/Sass files to be included in the
 * output CSS.
 * For more information on global stylesheets, visit the documentation:
 * https://ionicframework.com/docs/layout/global-stylesheets
 */

/* Core CSS required for Ionic components to work properly */
@import "~@ionic/angular/css/core.css";

/* Basic CSS for apps built with Ionic */
@import "~@ionic/angular/css/normalize.css";
@import "~@ionic/angular/css/structure.css";
@import "~@ionic/angular/css/typography.css";
@import '~@ionic/angular/css/display.css';

/* Optional CSS utils that can be commented out */
@import "~@ionic/angular/css/padding.css";
@import "~@ionic/angular/css/float-elements.css";
@import "~@ionic/angular/css/text-alignment.css";
@import "~@ionic/angular/css/text-transformation.css";
@import "~@ionic/angular/css/flex-utils.css";
.custom-modal-css .modal-wrapper {
    width: 95%;
    height: 90%;
    top: 5%;
    position: absolute; 
    display: block;  
    border-top-left-radius: 2em;
    border-top-right-radius: 2em;
    border-bottom-right-radius: 2em;
    border-bottom-left-radius: 2em;
    background: rgba(0, 0, 0, 0.8) !important;
}

.mat-expansion-panel-header-description, 
.mat-expansion-indicator::after {
    color: #444444;
}

.half-modal {
  align-items: flex-end;

  .modal-wrapper {
    height: 60% !important;
  }
  
  ion-header ion-toolbar:first-of-type {
    padding-top: 0;
  }
  ion-backdrop {
    --backdrop-opacity: var(--ion-backdrop-opacity, 0.6);
  }
}

// body{//iosのノッチ対応。FOOTERが削れるため、一旦コメントアウト
//     margin-top: constant(safe-area-inset-top);
//     margin-top: env(safe-area-inset-top);
// }

// .customToast_correct
// {
//     background:url(/assets/img/thumbup_good_emoticon_small4.svg);
//     background-repeat:  no-repeat;
//     background-size:contain;
//     width:30%;
//     height:auto;
//     transition-duration: 3s;
// }


// .customToast_wrong
// {
//     background:url(/assets/img/ok_emoji_perfect_5263638.png);
//     background-repeat: no-repeat;
//     background-size:contain;
//     position: center ;
//     width:10em;
//     // height:10px;
//     transition-duration: 3s;
// }